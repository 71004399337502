<template>
  <OBannerCarousel
    :items="banners"
    :items-per-slide="1"
    :loading="loading"
    :sizes="{
      mobile: { w: '991', h: '520' },
      desktop: { w: '1416', h: '360' },
    }"
    with-pixel-ratio
  />
</template>

<script lang="ts" setup>
import { ref, defineComponent } from 'vue'
import { useBanners } from '@/stores/banners'

import type { BannerCollection } from '@/modules/nuxt-api/models/Banner'

import OBannerCarousel from '@/components/organisms/BannerCarousel/OBannerCarousel.vue'

defineComponent({ name: 'HomeBannerCarousel' })

const loading = ref(process.client)
const banners = ref()

await useHydrationData(
  'home-banners',
  () => useBanners().getBannersForHome(),
  {
    then: ({ data }) => {
      banners.value = data.value?.filter?.((banner: BannerCollection) => banner.type === 'newmain')?.[0]?.bannersList
      loading.value = false
    },
    catch: () => undefined,
    lazy: true
  }
)
</script>
